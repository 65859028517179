<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row wrap>
                <app-card
                        :fullBlock="true"
                        colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-tabs
                            v-model="shippingDocumentsOverviewCurrentTab"
                            color="success"
                            background-color="grey lighten-2"
                            slider-color="appic-green"
                    >
                        <v-tab key="pl" @click="changeExportParams(0)">{{ $t('message.packingLists') }}</v-tab>
                        <v-tab key="si" @click="changeExportParams(1)">{{ $t('message.si') }}</v-tab>
                        <v-tab key="lca" @click="changeExportParams(2)">{{ $t('message.lcas') }}</v-tab>
                        <v-tab key="ipa" @click="changeExportParams(3)">{{ $t('message.ipas') }}</v-tab>
                        <v-tab key="cn" @click="changeExportParams(4)">{{ $t('message.coverNotes') }}</v-tab>
                        <v-spacer></v-spacer>
                        <ExportTable
                            :file-name="fileName"
                            :has-add-function="hasAddFunction"
                            :cols-to-delete="colsToDelete"
                            :table-id="tableId"
                            class="mt-1 mr-4 float-right"
                            style="padding-top: 2px !important;"
                            @add-action="newDocument(document)"
                        ></ExportTable>
                    </v-tabs>
                    <v-tabs-items v-model="shippingDocumentsOverviewCurrentTab">
                        <v-tab-item key="pl-list">
                            <v-card flat>
                                <PackingListsListing></PackingListsListing>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="si-list">
                            <v-card flat>
                                <ShippingInstructionsListing></ShippingInstructionsListing>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="lca-list">
                            <v-card flat>
                                <LcasListing></LcasListing>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="ipa-list">
                            <v-card flat>
                                <IpasListing></IpasListing>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="cn-list">
                            <v-card flat>
                                <CoverNotesListing></CoverNotesListing>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    // import ExportTable from "../../../components/Appic/ExportTable";
    // import PackingListsListing from "../../../components/Appic/PackingListsListing";
    // import IpasListing from "../../../components/Appic/IpasListing";
    // import LcasListing from "../../../components/Appic/LcasListing";
    // import ShippingInstructionsListing from "Components/Appic/ShippingInstructionsListing";
    // import CoverNotesListing from "Components/Appic/CoverNotesListing";
    import { mapFields } from "vuex-map-fields";

    const ExportTable = () => import("../../../components/Appic/ExportTable");
    const PackingListsListing = () => import("../../../components/Appic/PackingListsListing");
    const IpasListing = () => import("../../../components/Appic/IpasListing");
    const LcasListing = () => import("../../../components/Appic/LcasListing");
    const ShippingInstructionsListing = () => import("Components/Appic/ShippingInstructionsListing");
    const CoverNotesListing = () => import("Components/Appic/CoverNotesListing");

    export default {
        name: "ShippingDocumentsOverview",
        components: {
            CoverNotesListing,
            ExportTable,
            IpasListing,
            LcasListing,
            PackingListsListing,
            ShippingInstructionsListing
        },
        title: '',
        data() {
            return {
                loader: false,
                active: null,
                colsToDelete: {
                    start_col: 10,
                    ncols: 1
                },
                fileName: 'packing_lists',
                hasAddFunction: true,
                documentType: 'pl',
                document: 'packinglists',
                tableId: 'packingListTable'
            }
        },
        computed: {
            ...mapFields('runtime',{
                shippingDocumentsOverviewCurrentTab: 'shippingDocumentsOverviewCurrentTab',
                openedTabs: 'openedTabs'
            })
        },
        methods: {
            changeExportParams(documentType) {
                switch (documentType) {
                    case 0:
                        this.colsToDelete = {start_col: 13, ncols: 1}
                        this.fileName = 'packing_lists'
                        this.hasAddFunction = true
                        this.document = 'packinglists'
                        this.documentType = 'pl'
                        this.tableId = 'packingListTable'
                        break;
                    case 1:
                        this.colsToDelete = {start_col: 6, ncols: 1}
                        this.fileName = 'sdas'
                        this.hasAddFunction = true
                        this.document = 'sdas'
                        this.documentType = 'si'
                        this.tableId = 'shippingInstructionTable'
                        break;
                    case 2:
                        this.colsToDelete = {start_col: 9, ncols: 1}
                        this.fileName = 'lcas'
                        this.hasAddFunction = true
                        this.document = 'lcas'
                        this.documentType = 'lca'
                        this.tableId = 'letterOfCreditAdviceTable'
                        break;
                    case 3:
                        this.colsToDelete = {start_col: 8, ncols: 1}
                        this.fileName = 'ipas'
                        this.hasAddFunction = true
                        this.document = 'ipas'
                        this.documentType = 'ipa'
                        this.tableId = 'importPermitAdviceTable'
                        break;
                    case 4:
                        this.colsToDelete = {start_col: 9, ncols: 1}
                        this.fileName = 'cover_notes'
                        this.hasAddFunction = true
                        this.document = 'covernotes'
                        this.documentType = 'cn'
                        this.tableId = 'coverNoteTable'
                        break;
                }
            },
            newDocument (document) {
                let tab = window.open('/v1/' + document + '/add','_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            }
        },
        created(){
            this.changeExportParams(this.shippingDocumentsOverviewCurrentTab)
        },
        mounted(){
            this.$title = this.$t('message.titles.shippingDocuments')
        }
    }
</script>

<style>
    .v-data-table td {
        padding: 0 5px;
    }
    .v-data-table td {
        padding: 0 5px;
        font-size: 0.8rem !important;
    }
    .font-xs {
        font-size: 0.65rem;
    }
    .mt-5px {
        margin-top: 5px !important;
    }
    .mt-6px {
        margin-top: 6px !important;
    }
</style>